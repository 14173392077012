.file-selector-panel {
    cursor: pointer;
    color: #dadada;
    background: #f7f8fa;
    border: 1px dashed #d9dbde;
}

.file-selector-panel:hover {
    background: rgb(228, 228, 234);
    border: 1px dashed #818484;
}

.file-selector-item{
    font-size: 0.9em;
    color: #565555;
}

.file-selector-item-name{
    font-size: 0.9em;
    color: #565555;
}

.file-selector-item-name:hover{
    cursor: pointer;
    text-decoration: underline;
}

.file-selector-item-details{
    margin-top: 1px;
    font-size: 0.6em;
    color: #939292;
}